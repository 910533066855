@tailwind base;
@tailwind components;


@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

h1 {
  @apply font-bold mt-0;
  font-size: 22px;
  font-family: 'Roboto Slab', serif;
}
h2 {
  @apply font-normal mt-0;
  font-size: 20px;
  font-family: 'Roboto Slab', serif;
}
h3 {
  @apply font-normal mt-0;
  font-size: 18px;
  font-family: 'Roboto Slab', serif;
}
h4 {
  @apply font-bold mt-0;
  font-size: 14px;
  font-family: 'Roboto Slab', serif;
}
h5 {
  @apply font-normal mt-0;
  font-size: 14px;
  font-family: 'Roboto Slab', serif;
}
h6 {
  @apply font-normal mt-0;
  font-size: 12px;
  font-family: 'Roboto', serif;
}

p {
  font-size: 14px;
  font-family: 'Roboto', serif;
}

a {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}

.label-text {
  @apply text-primary text-opacity-60;
}

.btnBack {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  box-shadow: 0px 1px 2px rgba(41, 127, 135, 0.15);
  font-size: 14px;
}

.btn {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  box-shadow: 0px 4px 6px rgba(41, 127, 135, 0.15);
}

.btn.btn-ghost, .btn.btn-link {
  box-shadow: none;
}

.bg-primary .badge, .bg-primary {
  color: hsl(var(--nc));
}

.bg-primary .checkbox-mark {
  border: 1px solid hsl(var(--nc))
}

.header--layout__small {
  @apply flex items-center justify-between w-full p-3 h-16 bg-primary text-primary-content;
}

.tab {
  height: auto;
  opacity: 0.5;
  color: hsl(var(--p));
  --tab-padding: 1.18rem;
}



.downshift-options {
  @apply rounded-md input absolute z-10 left-0 right-0 top-16 shadow-lg
}

.downshift-options-focus {
  @apply bg-gray-200
}

.downshift-options-selected {
  @apply font-bold
}

.invalid-feedback {
  @apply mt-1 text-xs text-red-500
}
.invalid-tooltip {
  @apply absolute z-10 hidden w-4 font-normal leading-normal text-white rounded p-2 bg-red-700
}
.is-invalid {
  @apply border border-red-500
}
.is-invalid:focus {
  @apply border border-red-500 ring
}





.txt {
  @apply text-base font-light leading-relaxed mt-0 mb-4
}
.txt-sm {
  @apply font-normal leading-normal mt-0 mb-4 
}
.txt-lg {
  @apply text-lg font-light leading-relaxed mt-6 mb-4
}

.card {
  @apply rounded-xl
}

.card.white {
  @apply bg-white;
  box-shadow: 0px 0px 20px rgba(41, 127, 135, 0.2);
}

.card.bg-primary {
  box-shadow: 0px 0px 20px rgba(41, 127, 135, 0.2);
}

.scrolling-wrapper {
  @apply flex flex-nowrap	overflow-x-auto
}


/* Hide scrollbar for Chrome, Safari and Opera */
.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrolling-wrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrolling-wrapper .card {
  justify-content: center;
  flex: 0 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.categories-vertical-wrapper {
  height: calc(100vh - 260px);
}



.motion-tabs {
  display: flex;
  position: relative;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto;
  background: white;
}

.motion-tabs::-webkit-scrollbar {
  display: none;
}

.motion-tabs-list {
  display: flex;
  border-bottom: var(--line-size) solid #fff;
}

.motion-tabs-list__item {
  margin: 0 0.75rem;
}

.motion-tabs-list__tab {
  display: block;
  color: #5abcbe;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 6px;
  background: none;
  transition: background-color 600ms cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  text-decoration: none;
  font-weight: bold;
  white-space: nowrap;
  opacity: 0.4;
}

.motion-tabs-list__tab.active {
  opacity: 1;
}

.motion-tabs-list__tab:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  height: var(--line-size);
  width: 100%;
}

.motion-tabs-list__tab:hover {
  color: var(--hover-colour);
}

.motion-tabs-list__tab.active:after {
  background-color: var(--line-colour);
}

.motion-tabs-list__tab.active.animating:after {
  background-color: transparent;
}

.motion-tabs-list__underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: var(--line-size);
  background-color: var(--line-colour);
}

.shadow-top {
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
}

.ellipsis-1-line {
  overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 1; /* number of lines to show */
 -webkit-box-orient: vertical;
}

.ellipsis-2-line {
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.sub-navbar {
  min-height: 70px;
}

.sidebar--points {
  @apply rounded-full flex flex-col items-center justify-center w-12 h-12 text-white text-sm font-semibold;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 201, 63, 0.05);
}

.swap-modal-bottom {
  @apply bg-primary bg-opacity-20 absolute rounded-full;
  height: 6px;
  width: 60px;
  left: 50%;
  top: 15px;
  transform: translate(-50%, -50%);
}

.btn-disabled, .btn-disabled:hover, .btn[disabled], .btn[disabled]:hover {
  box-shadow: none;
}

.sticky {
  position: sticky;
  top: 0;
}

.sticky.active {
  background-color: white;
}

@media(max-width: 470px) {
  .tab {
    --tab-padding: 1.03rem;
  }
}

@media(max-width: 440px) {
  .tab {
    --tab-padding: 0.82rem;
  }
}

@media(max-width: 420px) {
  .tab {
    --tab-padding: 0.7rem;
  }
}

@media(max-width: 400px) {
  .tab {
    --tab-padding: 1.3rem;
  }
}

@media(max-width: 370px) {
  .tab {
    --tab-padding: 1.05rem;
  }
}

@tailwind utilities;